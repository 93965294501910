import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import AboutUs from 'components/AboutUs';

export default function CarryUsPage(props) {
  let {
    data: { contentfulUtilityJsonModel },
  } = props;

  if (!contentfulUtilityJsonModel.content) {
    return null;
  }

  return (
    <Layout>
      <AboutUs content={contentfulUtilityJsonModel.content} />
    </Layout>
  );
}

export const query = graphql`
  query {
    contentfulUtilityJsonModel(type: { eq: "AboutPage" }) {
      title
      type
      content {
        title
        subtitle
        items {
          timeLineImage
          year
          shortDescription
          articleTitle
          articleImage
          articleImageCaption
          articleSubtitle
          articleText
        }
      }
    }
  }
`;
